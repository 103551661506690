


jQuery(document).ready(function () {

  jQuery(".masonry-grid").each(function () {

    var origin_left = true;
    if (jQuery(this).attr('data-origin-left') == 'true') {
      origin_left = false;
    }
    else {
      origin_left = true;
    }

    // Portfolio masonry layout
    var $grid = jQuery(this).masonry({
      // options
      itemSelector: ".grid-item",
      percentPosition: true,
      columnWidth: ".grid-sizer",
      horizontalOrder: false,
      originLeft: origin_left
    });

    // layout Masonry after each image loads
    $grid.imagesLoaded().progress(function () {
      $grid.masonry("layout");
    });
  
    //jQuery(".m-grid").featherlightGallery();
    
  });
});
