/*! modernizr 3.5.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-flexbox-flexboxlegacy-flexboxtweener-inlinesvg-svg-video-setclasses-shiv !*/
! function(e, t, n) {
  function r(e, t) {
    return typeof e === t
  }

  function o() {
    var e, t, n, o, a, i, s;
    for (var l in E)
      if (E.hasOwnProperty(l)) {
        if (e = [], t = E[l], t.name && (e.push(t.name.toLowerCase()), t.options && t.options.aliases && t.options.aliases.length))
          for (n = 0; n < t.options.aliases.length; n++) e.push(t.options.aliases[n].toLowerCase());
        for (o = r(t.fn, "function") ? t.fn() : t.fn, a = 0; a < e.length; a++) i = e[a], s = i.split("."), 1 === s.length ? Modernizr[s[0]] = o : (!Modernizr[s[0]] || Modernizr[s[0]] instanceof Boolean || (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])), Modernizr[s[0]][s[1]] = o), C.push((o ? "" : "no-") + s.join("-"))
      }
  }

  function a(e) {
    var t = S.className,
      n = Modernizr._config.classPrefix || "";
    if (b && (t = t.baseVal), Modernizr._config.enableJSClass) {
      var r = new RegExp("(^|\\s)" + n + "no-js(\\s|$)");
      t = t.replace(r, "$1" + n + "js$2")
    }
    Modernizr._config.enableClasses && (t += " " + n + e.join(" " + n), b ? S.className.baseVal = t : S.className = t)
  }

  function i() {
    return "function" != typeof t.createElement ? t.createElement(arguments[0]) : b ? t.createElementNS.call(t, "http://www.w3.org/2000/svg", arguments[0]) : t.createElement.apply(t, arguments)
  }

  function s(e, t) {
    return !!~("" + e).indexOf(t)
  }

  function l(e) {
    return e.replace(/([a-z])-([a-z])/g, function(e, t, n) {
      return t + n.toUpperCase()
    }).replace(/^-/, "")
  }

  function c(e, t) {
    return function() {
      return e.apply(t, arguments)
    }
  }

  function u(e, t, n) {
    var o;
    for (var a in e)
      if (e[a] in t) return n === !1 ? e[a] : (o = t[e[a]], r(o, "function") ? c(o, n || t) : o);
    return !1
  }

  function f(e) {
    return e.replace(/([A-Z])/g, function(e, t) {
      return "-" + t.toLowerCase()
    }).replace(/^ms-/, "-ms-")
  }

  function d(t, n, r) {
    var o;
    if ("getComputedStyle" in e) {
      o = getComputedStyle.call(e, t, n);
      var a = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));
      else if (a) {
        var i = a.error ? "error" : "log";
        a[i].call(a, "getComputedStyle returning null, its possible modernizr test results are inaccurate")
      }
    } else o = !n && t.currentStyle && t.currentStyle[r];
    return o
  }

  function p() {
    var e = t.body;
    return e || (e = i(b ? "svg" : "body"), e.fake = !0), e
  }

  function m(e, n, r, o) {
    var a, s, l, c, u = "modernizr",
      f = i("div"),
      d = p();
    if (parseInt(r, 10))
      for (; r--;) l = i("div"), l.id = o ? o[r] : u + (r + 1), f.appendChild(l);
    return a = i("style"), a.type = "text/css", a.id = "s" + u, (d.fake ? d : f).appendChild(a), d.appendChild(f), a.styleSheet ? a.styleSheet.cssText = e : a.appendChild(t.createTextNode(e)), f.id = u, d.fake && (d.style.background = "", d.style.overflow = "hidden", c = S.style.overflow, S.style.overflow = "hidden", S.appendChild(d)), s = n(f, e), d.fake ? (d.parentNode.removeChild(d), S.style.overflow = c, S.offsetHeight) : f.parentNode.removeChild(f), !!s
  }

  function h(t, r) {
    var o = t.length;
    if ("CSS" in e && "supports" in e.CSS) {
      for (; o--;)
        if (e.CSS.supports(f(t[o]), r)) return !0;
      return !1
    }
    if ("CSSSupportsRule" in e) {
      for (var a = []; o--;) a.push("(" + f(t[o]) + ":" + r + ")");
      return a = a.join(" or "), m("@supports (" + a + ") { #modernizr { position: absolute; } }", function(e) {
        return "absolute" == d(e, null, "position")
      })
    }
    return n
  }

  function v(e, t, o, a) {
    function c() {
      f && (delete P.style, delete P.modElem)
    }
    if (a = r(a, "undefined") ? !1 : a, !r(o, "undefined")) {
      var u = h(e, o);
      if (!r(u, "undefined")) return u
    }
    for (var f, d, p, m, v, g = ["modernizr", "tspan", "samp"]; !P.style && g.length;) f = !0, P.modElem = i(g.shift()), P.style = P.modElem.style;
    for (p = e.length, d = 0; p > d; d++)
      if (m = e[d], v = P.style[m], s(m, "-") && (m = l(m)), P.style[m] !== n) {
        if (a || r(o, "undefined")) return c(), "pfx" == t ? m : !0;
        try {
          P.style[m] = o
        } catch (y) {}
        if (P.style[m] != v) return c(), "pfx" == t ? m : !0
      }
    return c(), !1
  }

  function g(e, t, n, o, a) {
    var i = e.charAt(0).toUpperCase() + e.slice(1),
      s = (e + " " + T.join(i + " ") + i).split(" ");
    return r(t, "string") || r(t, "undefined") ? v(s, t, o, a) : (s = (e + " " + _.join(i + " ") + i).split(" "), u(s, t, n))
  }

  function y(e, t, r) {
    return g(e, n, n, t, r)
  }
  var C = [],
    E = [],
    w = {
      _version: "3.5.0",
      _config: {
        classPrefix: "",
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0
      },
      _q: [],
      on: function(e, t) {
        var n = this;
        setTimeout(function() {
          t(n[e])
        }, 0)
      },
      addTest: function(e, t, n) {
        E.push({
          name: e,
          fn: t,
          options: n
        })
      },
      addAsyncTest: function(e) {
        E.push({
          name: null,
          fn: e
        })
      }
    },
    Modernizr = function() {};
  Modernizr.prototype = w, Modernizr = new Modernizr, Modernizr.addTest("svg", !!t.createElementNS && !!t.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect);
  var S = t.documentElement,
    b = "svg" === S.nodeName.toLowerCase();
  b || ! function(e, t) {
    function n(e, t) {
      var n = e.createElement("p"),
        r = e.getElementsByTagName("head")[0] || e.documentElement;
      return n.innerHTML = "x<style>" + t + "</style>", r.insertBefore(n.lastChild, r.firstChild)
    }

    function r() {
      var e = C.elements;
      return "string" == typeof e ? e.split(" ") : e
    }

    function o(e, t) {
      var n = C.elements;
      "string" != typeof n && (n = n.join(" ")), "string" != typeof e && (e = e.join(" ")), C.elements = n + " " + e, c(t)
    }

    function a(e) {
      var t = y[e[v]];
      return t || (t = {}, g++, e[v] = g, y[g] = t), t
    }

    function i(e, n, r) {
      if (n || (n = t), f) return n.createElement(e);
      r || (r = a(n));
      var o;
      return o = r.cache[e] ? r.cache[e].cloneNode() : h.test(e) ? (r.cache[e] = r.createElem(e)).cloneNode() : r.createElem(e), !o.canHaveChildren || m.test(e) || o.tagUrn ? o : r.frag.appendChild(o)
    }

    function s(e, n) {
      if (e || (e = t), f) return e.createDocumentFragment();
      n = n || a(e);
      for (var o = n.frag.cloneNode(), i = 0, s = r(), l = s.length; l > i; i++) o.createElement(s[i]);
      return o
    }

    function l(e, t) {
      t.cache || (t.cache = {}, t.createElem = e.createElement, t.createFrag = e.createDocumentFragment, t.frag = t.createFrag()), e.createElement = function(n) {
        return C.shivMethods ? i(n, e, t) : t.createElem(n)
      }, e.createDocumentFragment = Function("h,f", "return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(" + r().join().replace(/[\w\-:]+/g, function(e) {
        return t.createElem(e), t.frag.createElement(e), 'c("' + e + '")'
      }) + ");return n}")(C, t.frag)
    }

    function c(e) {
      e || (e = t);
      var r = a(e);
      return !C.shivCSS || u || r.hasCSS || (r.hasCSS = !!n(e, "article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}")), f || l(e, r), e
    }
    var u, f, d = "3.7.3",
      p = e.html5 || {},
      m = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
      h = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
      v = "_html5shiv",
      g = 0,
      y = {};
    ! function() {
      try {
        var e = t.createElement("a");
        e.innerHTML = "<xyz></xyz>", u = "hidden" in e, f = 1 == e.childNodes.length || function() {
          t.createElement("a");
          var e = t.createDocumentFragment();
          return "undefined" == typeof e.cloneNode || "undefined" == typeof e.createDocumentFragment || "undefined" == typeof e.createElement
        }()
      } catch (n) {
        u = !0, f = !0
      }
    }();
    var C = {
      elements: p.elements || "abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video",
      version: d,
      shivCSS: p.shivCSS !== !1,
      supportsUnknownElements: f,
      shivMethods: p.shivMethods !== !1,
      type: "default",
      shivDocument: c,
      createElement: i,
      createDocumentFragment: s,
      addElements: o
    };
    e.html5 = C, c(t), "object" == typeof module && module.exports && (module.exports = C)
  }("undefined" != typeof e ? e : this, t), Modernizr.addTest("video", function() {
    var e = i("video"),
      t = !1;
    try {
      t = !!e.canPlayType, t && (t = new Boolean(t), t.ogg = e.canPlayType('video/ogg; codecs="theora"').replace(/^no$/, ""), t.h264 = e.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(/^no$/, ""), t.webm = e.canPlayType('video/webm; codecs="vp8, vorbis"').replace(/^no$/, ""), t.vp9 = e.canPlayType('video/webm; codecs="vp9"').replace(/^no$/, ""), t.hls = e.canPlayType('application/x-mpegURL; codecs="avc1.42E01E"').replace(/^no$/, ""))
    } catch (n) {}
    return t
  }), Modernizr.addTest("inlinesvg", function() {
    var e = i("div");
    return e.innerHTML = "<svg/>", "http://www.w3.org/2000/svg" == ("undefined" != typeof SVGRect && e.firstChild && e.firstChild.namespaceURI)
  });
  var x = "Moz O ms Webkit",
    T = w._config.usePrefixes ? x.split(" ") : [];
  w._cssomPrefixes = T;
  var _ = w._config.usePrefixes ? x.toLowerCase().split(" ") : [];
  w._domPrefixes = _;
  var N = {
    elem: i("modernizr")
  };
  Modernizr._q.push(function() {
    delete N.elem
  });
  var P = {
    style: N.elem.style
  };
  Modernizr._q.unshift(function() {
    delete P.style
  }), w.testAllProps = g, w.testAllProps = y, Modernizr.addTest("flexbox", y("flexBasis", "1px", !0)), Modernizr.addTest("flexboxlegacy", y("boxDirection", "reverse", !0)), Modernizr.addTest("flexboxtweener", y("flexAlign", "end", !0)), o(), a(C), delete w.addTest, delete w.addAsyncTest;
  for (var j = 0; j < Modernizr._q.length; j++) Modernizr._q[j]();
  e.Modernizr = Modernizr
}(window, document);